// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { BROKER_URL } from 'src/config-global';
import { useAuthContext } from 'src/auth/hooks';
import ReactGA from 'react-ga4';

// locales
// components

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const data = useAuthContext();

  const user = data.user.multiFactor.user;

  const handleClick = () => {
    ReactGA.event({
      category: 'Button Clicks',
      action: 'Clicked',
      label: 'Talk to Exit Advisor',
    });
  };

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        {/* <Box sx={{ position: 'relative' }}>
          <Avatar src={user?.photoURL} alt={user?.displayName} sx={{ width: 48, height: 48 }} />
          <Label
            color="success"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
            }}
          >
            Free
          </Label>
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {user?.email}
          </Typography>
        </Stack> */}

        <Button
          variant="contained"
          href={`${BROKER_URL}/?name=${user.displayName}&email=${user.email}`}
          target="_blank"
          color="warning"
          rel="noopener"
          onClick={handleClick}
        >
          Talk to Exit Advisor
        </Button>
      </Stack>
    </Stack>
  );
}
