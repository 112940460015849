// eslint-disable-next-line import/no-extraneous-dependencies
import firebase from 'firebase/compat/app';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBafH1DG7JZWPffJjZROP3Z-x7oFmkOGAo',
  authDomain: 'dashboard-3fcb0.firebaseapp.com',
  projectId: 'dashboard-3fcb0',
  storageBucket: 'dashboard-3fcb0.appspot.com',
  messagingSenderId: '27025964576',
  appId: '1:27025964576:web:eb8b097721e8cbf97c8645',
  measurementId: 'G-5S74LTHDCL',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
