/* eslint-disable import/no-extraneous-dependencies */
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { endpoints } from 'src/utils/axios';
import { tokenConfig } from 'src/auth/context/jwt/utils';
// ----------------------------------------------------------------------

const initialState = {
  valuations: [],
  valuation: null,
  valuationsStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  valuationStatus: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'valuation',
  initialState,
  reducers: {
    // GET VALUATIONS
    getValuationsStart(state) {
      state.valuationsStatus.loading = true;
      state.valuationsStatus.empty = false;
      state.valuationsStatus.error = null;
    },
    getValuationsFailure(state, action) {
      state.valuationsStatus.loading = false;
      state.valuationsStatus.empty = false;
      state.valuationsStatus.error = action.payload;
    },
    getValuationsSuccess(state, action) {
      const valuations = action.payload;

      state.valuations = valuations;

      state.valuationsStatus.loading = false;
      state.valuationsStatus.empty = !valuations.length;
      state.valuationsStatus.error = null;
    },

    // GET VALUATION
    getValuationStart(state) {
      state.valuationStatus.loading = true;
      state.valuationStatus.error = null;
    },
    getValuationFailure(state, action) {
      state.valuationStatus.loading = false;
      state.valuationStatus.error = action.payload;
    },
    getValuationSuccess(state, action) {
      const valuation = action.payload;

      state.valuation = valuation;

      state.valuationStatus.loading = false;
      state.valuationStatus.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getValuations() {
  return async (dispatch) => {
    dispatch(slice.actions.getValuationsStart());
    try {
      const response = await axios.get('api/valuation');
      dispatch(slice.actions.getValuationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.getValuationsFailure(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getValuation(valuationId) {
  return async (dispatch) => {
    dispatch(slice.actions.getValuationStart());
    try {
      const response = await axios.get('api/valuation'.details, {
        params: {
          valuationId,
        },
      });
      dispatch(slice.actions.getValuationSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.getValuationFailure(error));
    }
  };
}
