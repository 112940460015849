/* eslint-disable import/no-extraneous-dependencies */
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { endpoints } from 'src/utils/axios';
import { tokenConfig } from 'src/auth/context/jwt/utils';
// ----------------------------------------------------------------------

const initialState = {
  checklist: null,
  checklistStatus: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'checklist',
  initialState,
  reducers: {
    // GET CHECKLIST
    getChecklistStart(state) {
      state.checklistStatus.loading = true;
      state.checklistStatus.error = null;
    },
    getChecklistFailure(state, action) {
      state.checklistStatus.loading = false;
      state.checklistStatus.error = action.payload;
    },
    getChecklistSuccess(state, action) {
      const checklist = action.payload;
      state.checklist = checklist;
      state.checklistStatus.loading = false;
      state.checklistStatus.error = null;
    },
    // UPDATE CHECKLIST
    updateChecklistStart(state) {
      state.checklistStatus.loading = true;
      state.checklistStatus.error = null;
    },
    updateChecklistFailure(state, action) {
      state.checklistStatus.loading = false;
      state.checklistStatus.error = action.payload;
    },
    updateChecklistSuccess(state, action) {
      const checklist = action.payload;
      state.checklist = checklist;
      state.checklistStatus.loading = false;
      state.checklistStatus.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getChecklist(valuation_id) {
  return async (dispatch) => {
    dispatch(slice.actions.getChecklistStart());
    try {
      const response = await axios.get(`api/checklist/${valuation_id}`);
      dispatch(slice.actions.getChecklistSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.getChecklistFailure(error));
    }
  };
}

export function updateChecklist(id, checklistData) {
  return async (dispatch) => {
    dispatch(slice.actions.updateChecklistStart());
    try {
      const response = await axios.put(`api/checklist/${id}`, checklistData);
      dispatch(slice.actions.updateChecklistSuccess(checklistData));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.updateChecklistFailure(error));
    }
  };
}
