// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useEffect } from 'react';
import ReduxProvider from 'src/redux/redux-provider';

// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// auth
// import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';
import { AuthProvider, AuthConsumer } from 'src/auth/context/firebase';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactGA from 'react-ga4';
// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  useEffect(() => {
    ReactGA.initialize('G-5S74LTHDCL');
  }, []);

  ReactGA.send({ hitType: 'pageview', page: '/my-path', title: 'Custom Title' });

  return (
    <AuthProvider>
      <ReduxProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SettingsDrawer />
              <ProgressBar />
              <AuthConsumer>
                <Router />
              </AuthConsumer>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </ReduxProvider>
    </AuthProvider>
  );
}
