import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import axios from 'src/utils/axios';
import firebase from '../../../firebase';
//
import { AuthContext } from './auth-context';
import { setSession, tokenConfig } from './utils';

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (!user) {
          dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
            },
          });
        } else if (user) {
          // console.log(user);

          // firebase.auth().currentUser.updateProfile({
          //   displayName: 'Alex',
          // });

          const token = await user.getIdToken();
          setSession(token);

          dispatch({
            type: 'INITIAL',
            payload: {
              user: {
                ...user,
                token,
              },
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    // console.log(email);
    try {
      let user;
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Signed in
          user = userCredential.user;
          // const response = await axios.get(endpoints.auth.me);
          // const { user } = response.data;
          return user.getIdToken();
        })
        .then((idToken) => {
          setSession(idToken);

          dispatch({
            type: 'LOGIN',
            payload: {
              user: {
                ...user,
                idToken,
              },
            },
          });
        })
        .catch((error) => {
          console.error('Error signing in:', error);
        });
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  // const loginWithGoogle = useCallback(async () => {
  //   const provider = new GoogleAuthProvider();

  //   await signInWithPopup(AUTH, provider);
  // }, []);

  // const loginWithGithub = useCallback(async () => {
  //   const provider = new GithubAuthProvider();

  //   await signInWithPopup(AUTH, provider);
  // }, []);

  // const loginWithTwitter = useCallback(async () => {
  //   const provider = new TwitterAuthProvider();

  //   await signInWithPopup(AUTH, provider);
  // }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const name = `${firstName} ${lastName}`;

    try {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((dataBeforeEmail) => {
          firebase.auth().onAuthStateChanged((user) => {
            user.sendEmailVerification();
            user.updateProfile({ name });
          });
        })
        .then((dataAfterEmail) => {
          firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
              const responseToken = await tokenConfig();
              try {
                axios.post('/api/client/', { email, name }).then((res) =>
                  // Sign up successful
                  dispatch({
                    type: 'REGISTER',
                    payload: {
                      user: {
                        ...user,
                        responseToken,
                      },
                    },
                  })
                );
              } catch (err) {
                dispatch({
                  type: 'REGISTER',
                  payload: {
                    user: null,
                  },
                });
              }
            }
          });
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    try {
      axios.post('/api/client/logout', {});
      await firebase.auth().signOut();
      setSession(null);
      dispatch({
        type: 'LOGOUT',
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'firebase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
      register,
      forgotPassword,
      // loginWithGoogle,
      // loginWithGithub,
      // loginWithTwitter,
    }),
    [
      status,
      state.user,
      //
      login,
      logout,
      register,
      forgotPassword,
      // loginWithGithub,
      // loginWithGoogle,
      // loginWithTwitter,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
